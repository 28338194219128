const BACKEND_API = {
  LOGIN: '/login',
  ADVERT_CAMPAIGNS: '/adver/campaign',
  ADVERT_CAMPAIGNS_ADD: '/adver/campaign/add',
  ADVERT_CAMPAIGNS_EDIT: '/adver/campaign/edit',
  ADVERT_CAMPAIGNS_STATUS: '/adver/campaign/update/status',
  ADVERT_CAMPAIGNS_ADD_LOG: '/adver/campaign/log/add',
  ADVERT_CAMPAIGNS_ALL_BY_COMPANYID: '/adver/campaign/all',
  ADVERT_UNITS: '/adver/unit',
  ADVERT_UNIT_STATUS: '/adver/unit/update/status',
  ADVERT_UNIT_ADD_LOG: '/adver/unit/log/add',
  ADVERT_UNIT_ADD: '/adver/unit/add',
  ADVERT_UNIT_UPDATE: '/adver/unit/edit',
  ADVERT_UNIT_FIND_BY_ID: '/adver/unit/findById',
  ADVERT_COUNTRY_CITY_LIST: '/adver/country/cities',
  UPLOAD: '/fileupload',
  FINANCE_REPORT_DAILY_STATEMENT: '/finance_report/daily_statement',
  FINANCE_REPORT_PREPAID_HISTORY: '/finance_report/prepaid_history',
  FINANCE_REPORT_BALANCE_AND_TODAY_ADVERT_COST: '/finance_report/balance_and_today_advert_cost',
  LANGUAGE: '/adver/language',
  ADVERT_SIZES: '/adver/sizes',

  INSIGHT_AUCTIONS: '/insight/auctions',
  ADVERT_DRAWER_DATA: '/adver/drawer/data',
};

export default BACKEND_API;
