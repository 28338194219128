import moment from 'moment';

class CacheItem {
  Expired: number;
  Data: any;

  constructor(data: any, expired: number) {
    this.Data = data;
    this.Expired = expired;
  }
}

export function putString(key: string, value: string) {
  localStorage.setItem(key, value);
}

export function getString(key: string): string {
  return localStorage.getItem(key) || '';
}

export function putObject(key: string, value: any) {
  if (typeof value == 'object') {
    const item = JSON.stringify(value);
    localStorage.setItem(key, item);
  } else localStorage.setItem(key, value);
}

export function getObject(key: string): any {
  const data = localStorage.getItem(key);

  if (data && data != '' && data != null) return JSON.parse(data);
  return null;
}

export function put(key: string, value: any, hours: number) {
  if (hours <= 0) {
    localStorage.setItem(key, JSON.stringify(value));
  } else {
    const day = moment().add(hours, 'h').unix();
    const item = new CacheItem(value, day);
    localStorage.setItem(key, JSON.stringify(item));
  }
}

export function get(key: string) {
  let ret = null;
  const data = localStorage.getItem(key) || '';
  try {
    const obj = JSON.parse(data);
    if (obj.Expired && obj.Expired > 0) {
      if (obj.Expired > moment().unix()) ret = obj.Data;
      else remove(key);
    } else {
      ret = obj;
    }
  } catch {
    return ret;
  }
  return ret;
}

export function remove(key: string) {
  localStorage.removeItem(key);
}

export function clear() {
  localStorage.clear();
}
