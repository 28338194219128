// @ts-nocheck
import React from 'react';
import { ApplyPluginsType, dynamic } from '/home/vsts/work/1/build-bundle-dsp-customer-extract/node_modules/@umijs/runtime';
import * as umiExports from './umiExports';
import { plugin } from './plugin';
import LoadingComponent from '@ant-design/pro-layout/es/PageLoading';

export function getRoutes() {
  const routes = [
  {
    "path": "/",
    "component": dynamic({ loader: () => import(/* webpackChunkName: 't__plugin-layout__Layout' */'/home/vsts/work/1/build-bundle-dsp-customer-extract/src/.umi-production/plugin-layout/Layout.tsx'), loading: LoadingComponent}),
    "routes": [
      {
        "path": "/user",
        "layout": false,
        "routes": [
          {
            "path": "/user",
            "routes": [
              {
                "name": "login",
                "path": "/user/login",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__user__Login' */'/home/vsts/work/1/build-bundle-dsp-customer-extract/src/pages/user/Login'), loading: LoadingComponent}),
                "exact": true
              }
            ]
          },
          {
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__404' */'/home/vsts/work/1/build-bundle-dsp-customer-extract/src/pages/404'), loading: LoadingComponent}),
            "exact": true
          }
        ]
      },
      {
        "path": "/index",
        "name": "index",
        "icon": "home",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__index' */'/home/vsts/work/1/build-bundle-dsp-customer-extract/src/pages/index'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/advert",
        "name": "advert",
        "icon": "ShareAltOutlined",
        "access": "canAdmin",
        "routes": [
          {
            "path": "/advert/campaign",
            "name": "campaign",
            "icon": "smile",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__advert__campaign' */'/home/vsts/work/1/build-bundle-dsp-customer-extract/src/pages/advert/campaign'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/advert/unit",
            "name": "unit",
            "icon": "smile",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__advert__unit' */'/home/vsts/work/1/build-bundle-dsp-customer-extract/src/pages/advert/unit'), loading: LoadingComponent}),
            "exact": true
          }
        ]
      },
      {
        "path": "/report",
        "name": "report",
        "icon": "LineChartOutlined",
        "access": "canAdmin",
        "routes": [
          {
            "path": "/report/data",
            "name": "data",
            "icon": "smile",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__report__data' */'/home/vsts/work/1/build-bundle-dsp-customer-extract/src/pages/report/data'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/report/finance",
            "name": "finance",
            "icon": "smile",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__report__finance' */'/home/vsts/work/1/build-bundle-dsp-customer-extract/src/pages/report/finance'), loading: LoadingComponent}),
            "exact": true
          }
        ]
      },
      {
        "path": "/profile",
        "name": "profile",
        "icon": "user",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__user__profile' */'/home/vsts/work/1/build-bundle-dsp-customer-extract/src/pages/user/profile'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/index.html",
        "redirect": "/index",
        "exact": true
      },
      {
        "path": "/",
        "redirect": "/index",
        "exact": true
      },
      {
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__404' */'/home/vsts/work/1/build-bundle-dsp-customer-extract/src/pages/404'), loading: LoadingComponent}),
        "exact": true
      }
    ]
  }
];

  // allow user to extend routes
  plugin.applyPlugins({
    key: 'patchRoutes',
    type: ApplyPluginsType.event,
    args: { routes },
  });

  return routes;
}
