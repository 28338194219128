import type { Settings as LayoutSettings } from '@ant-design/pro-layout';
import { PageLoading } from '@ant-design/pro-layout';
import type { RunTimeLayoutConfig } from 'umi';
import { history } from 'umi';
import RightContent from '@/components/RightContent';
import Footer from '@/components/Footer';
import { getCustomerInfo } from './services/user.service';
import defaultSettings from '../config/defaultSettings';
import * as Cache from './utils/cache.helper';
import { STORAGE_KEY } from './utils/consts';

const loginPath = '/user/login';

/** 获取用户信息比较慢的时候会展示一个 loading */
export const initialStateConfig = {
  loading: <PageLoading />,
};

export async function getInitialState(): Promise<{
  settings?: Partial<LayoutSettings>;
  customerInfo?: API.CustomerInfo;
  loading?: boolean;
  fetchUserInfo?: () => Promise<API.CustomerInfo | undefined>;
}> {
  const fetchUserInfo = () => {
    try {
      const user = getCustomerInfo();
      if (!user) {
        history.push(loginPath);
      }
      return user;
    } catch (error) {
      history.push(loginPath);
    }
    return undefined;
  };

  const customerInfo = await fetchUserInfo();

  return {
    fetchUserInfo,
    customerInfo,
    settings: defaultSettings,
  };
}

export const layout: RunTimeLayoutConfig = ({ initialState }) => {
  return {
    rightContentRender: () => <RightContent />,
    disableContentMargin: false,
    footerRender: () => <Footer />,
    onPageChange: () => {
      const { location } = history;
      // 如果没有登录，重定向到 login
      const tokenExpired = Cache.getObject(STORAGE_KEY.TOKEN_EXPIRED)
      if (!initialState?.customerInfo && location.pathname !== loginPath || tokenExpired) {
        history.push(loginPath);
      } else if (initialState?.customerInfo && location.pathname == loginPath) {
        history.push('/');
      }
    },
    links: [],
    menuHeaderRender: undefined,
    childrenRender: (children) => {
      return <>{children}</>;
    },
    ...initialState?.settings,
    logo: false
  };
};
