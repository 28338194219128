import { Space, Switch } from 'antd';
import React, { useRef, useState } from 'react';
import { useModel } from 'umi';
import Avatar from './AvatarDropdown';
import styles from './index.less';
import * as Cache from '@/utils/cache.helper';
import { STORAGE_KEY } from '@/utils/consts';
// import InfoModal from '@/components/Common/InfoModal';
import { ConsoleSqlOutlined } from '@ant-design/icons';

export type SiderTheme = 'light' | 'dark';

type TimezoneStorage = {
  timezone: number;
  city: string;
  checkedTimezoneSwitch: boolean;
};

// type InfoModal = {
//   showInfoModal: () => void;
// };

const GlobalHeaderRight: React.FC = () => {
  const { initialState } = useModel('@@initialState');

  const timezoneInfo = useRef<TimezoneStorage>(Cache.getObject(STORAGE_KEY.TIME_ZONE));
  const [city, setCity] = useState<string>(Cache.getObject(STORAGE_KEY.TIME_ZONE).city);
  // const InfoModalRef = useRef<InfoModal>();

  const defaultCheckedSwitch = Cache.getObject(STORAGE_KEY.TIME_ZONE).checkedTimezoneSwitch;

  if (!initialState || !initialState.settings) {
    return null;
  }

  const { navTheme, layout } = initialState.settings;
  let className = styles.right;

  // 临时方案 vvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvv
  const onChangeTimezone = (checked: boolean) => {
    if (checked) {
      timezoneInfo.current = {
        timezone: 8,
        city: '北京',
        checkedTimezoneSwitch: true,
      };
      Cache.putObject(STORAGE_KEY.TIME_ZONE, timezoneInfo.current);
      setCity('北京');
    } else {
      timezoneInfo.current = {
        timezone: -4,
        city: '蒙特利尔',
        checkedTimezoneSwitch: false,
      };
      Cache.putObject(STORAGE_KEY.TIME_ZONE, timezoneInfo.current);
      setCity('蒙特利尔');
    }
    window.location.reload();
    // InfoModalRef.current?.showInfoModal();
  };
  // ^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^

  if ((navTheme === 'dark' && layout === 'top') || layout === 'mix') {
    className = `${styles.right}  ${styles.dark}`;
  }
  return (
    <Space className={className}>
      {/* <Switch onChange={onChangeTimezone} defaultChecked={defaultCheckedSwitch} />
       <span style={{ display: 'block', width: '100px', color: 'white', textAlign: 'center' }}>
         {city} 时间
       </span> */}
      <Avatar menu />
      {/* <SelectLang className={styles.action} /> */}
    </Space>
  );
};
export default GlobalHeaderRight;
