// @ts-nocheck

  import HomeOutlined from '@ant-design/icons/es/icons/HomeOutlined';
import ShareAltOutlined from '@ant-design/icons/es/icons/ShareAltOutlined';
import SmileOutlined from '@ant-design/icons/es/icons/SmileOutlined';
import LineChartOutlined from '@ant-design/icons/es/icons/LineChartOutlined';
import UserOutlined from '@ant-design/icons/es/icons/UserOutlined'
  export default {
    HomeOutlined,
ShareAltOutlined,
SmileOutlined,
LineChartOutlined,
UserOutlined
  }