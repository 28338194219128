import { BACKEND_API } from '../environment';
import useRequest from '../utils/http.extend';
import * as Cache from '../utils/cache.helper';
import { STORAGE_KEY } from '../utils/consts';

async function Login(username: string, password: string) {
  const requestOptions = {
    method: 'POST',
    data: { UserName: username, Password: password },
  };

  return useRequest()<API.httpResponse>(BACKEND_API.LOGIN, requestOptions).then((res: any) => {
    if (res && res.code == 0 && res.data && res.data.customerInfo) {
      Cache.putObject(STORAGE_KEY.CUSTEMOR_INFO, res.data.customerInfo);
      Cache.putString(STORAGE_KEY.TOKEN, res.data.token);
      Cache.remove(STORAGE_KEY.TOKEN_EXPIRED);
    } else {
      return null;
    }

    return res.data.customerInfo as API.CustomerInfo;
  });
}

function getCustomerInfo() {
  return Cache.getObject(STORAGE_KEY.CUSTEMOR_INFO) || null;
}

function Logout() {
  Cache.remove(STORAGE_KEY.CUSTEMOR_INFO);
  Cache.remove(STORAGE_KEY.TOKEN);
}

export { Login, Logout, getCustomerInfo };
