/**
 * @see https://umijs.org/zh-CN/plugins/plugin-access
 * */
export default function access(initialState: { customerInfo?: API.CustomerInfo } | undefined) {
  const { customerInfo } = initialState ?? {};
  console.log(customerInfo);
  return {
    canAdmin: true,
  };
}
