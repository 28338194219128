// @ts-nocheck

import HomeOutlined from '@ant-design/icons/HomeOutlined';
import ShareAltOutlined from '@ant-design/icons/ShareAltOutlined';
import SmileOutlined from '@ant-design/icons/SmileOutlined';
import LineChartOutlined from '@ant-design/icons/LineChartOutlined';
import UserOutlined from '@ant-design/icons/UserOutlined'

export default {
  HomeOutlined,
ShareAltOutlined,
SmileOutlined,
LineChartOutlined,
UserOutlined
}
    