import { extend } from 'umi-request';
import { config } from '../environment';
import * as Cache from './cache.helper';
import { STORAGE_KEY } from './consts';
import { history } from 'umi';
import { stringify } from 'querystring';

function useRequest() {
  const token = Cache.getString(STORAGE_KEY.TOKEN);
  //const { initialState, setInitialState } = useModel('@@initialState');
  const request = extend({
    prefix: `${config.rootURL}/api/v1/customer`,
    timeout: 10000,
    headers: {
      'Content-Type': 'application/json',
      Authorization: token || '',
    },
    errorHandler(error) {
      const { response } = error;
      if (response && response.status) {
          if (response.status === 401 && window.location.pathname !== '/user/login') {
            Cache.remove(STORAGE_KEY.CUSTEMOR_INFO);
            Cache.remove(STORAGE_KEY.TOKEN);
            Cache.putObject(STORAGE_KEY.TOKEN_EXPIRED, true);
            history.push('/')
            //loginOut()
          }
      }
    },
  });

  return request;
}

const loginOut = () => {
  const { query = {}, search, pathname } = history.location;
  const { redirect } = query;
  // Note: There may be security issues, please note
  if (window.location.pathname !== '/user/login' && !redirect) {
    history.replace({
      pathname: '/user/login',
      search: stringify({
        redirect: pathname + search,
      }),
    });
  }
};

export default useRequest;
