import { useIntl } from 'umi';
import { DefaultFooter } from '@ant-design/pro-layout';

const Footer: React.FC = () => {
  const intl = useIntl();
  const defaultMessage = intl.formatMessage({
    id: 'app.copyright.produced',
    defaultMessage: 'Mugua Technology Inc.',
  });


  return <DefaultFooter copyright={`${defaultMessage}`}  />;
};

export default Footer;
